<template>
  <wallet-table
    :loading="loading"
    :table-data="walletRules.brandTerms"
    :search-text="searchText"
    @update-total-items="updateTotalItems"
    @load-wallet-rules="$emit('load-wallet-rules')"
  />
</template>

<script>
import WalletTable from './WalletTable.vue'

export default {
  name: 'BrandTerms',
  components: {
    WalletTable,
  },
  props: {
    walletRules: {
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default() {
        return ''
      },
    },
  },
  methods: {
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.$emit('update-total-items', currentPageCount, totalItemsCount)
    },
  },
  emits: ['update-total-items'],
}
</script>
